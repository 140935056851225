<template>
    <div class="CompanyProfile">
        <div class="container">
            <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="company-profile-ruleForm">
                <el-form-item label="公司简介" prop="description">
                    <vue-tinymce v-model="ruleForm.description" :setting="setting" />
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="submitForm('ruleForm')" v-loading.fullscreen.lock="fullscreenLoading">保存</el-button>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script>
    export default {
        name: "CompanyProfile",
        data(){
            return{
                action:'/api/carousel/test',
                fullscreenLoading: false,//loading框
                ruleForm: {},   //表单数据
                rules: {
                    description: [
                        { required: true, message: '请填写简介', trigger: 'blur' }
                    ],
                },
                setting: {
                    menubar: false,
                    toolbar: "undo redo | fullscreen | formatselect alignleft aligncenter alignright alignjustify | link unlink | numlist bullist | image media table | fontselect fontsizeselect forecolor backcolor | bold italic underline strikethrough | indent outdent | superscript subscript | removeformat |",
                    toolbar_drawer: "sliding",
                    quickbars_selection_toolbar: "removeformat | bold italic underline strikethrough | fontsizeselect forecolor backcolor",
                    plugins: "link image media table lists fullscreen quickbars",
                    language: 'zh_CN',
                    height: 600,
                    // inline:true,
                    images_upload_handler:(a,b,c)=>{
                        console.log(a);
                        console.log(b);
                        console.log(c);
                    },
                }
            }
        },
        created(){
            this.getData();
        },
        methods:{
            //获取数据
            async getData(){
                this.fullscreenLoading = true;
                try{
                    const {data} = await this.$axios({
                        url:'/api/admincompany/showall',
                    });
                    this.fullscreenLoading = false;
                    if(data.code){
                        this.ruleForm = data.data;
                    }else{
                        this.$message.error(data.msg);
                    }
                }catch (e) {
                    this.fullscreenLoading = false;
                    this.$message.error('网络错误，请稍后重试');
                    console.log(e);
                }
            },
            //点击保存
            async submitForm(formName) {
                this.$refs[formName].validate(async valid => {
                    if (valid) {
                        this.fullscreenLoading = true;
                        try {
                            const {data} = await this.$axios({
                                method:'POST',
                                url:'/api/admincompany/updateintroduction',
                                data:{
                                    description:this.ruleForm.description.replaceAll("'","\\'"),
                                },
                            });
                            this.fullscreenLoading = false;
                            if(data.code){
                                this.$message({
                                    message: data.msg,
                                    type: 'success',
                                });
                            }else{
                                this.$message.error(data.msg);
                            }
                        }catch (e) {
                            this.fullscreenLoading = false;
                            this.$message.error('网络错误，请稍后重试');
                        }
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
        },
    }
</script>

<style scoped>

</style>